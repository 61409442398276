// The Main Object
let Home = {

    grid    : document.querySelector('.pa-pictures'),
    markers : document.querySelectorAll('.journey-marker'),
    label   : document.querySelector('.journey-label'),

    // Init Function
    liftoff : () => {
        Home.initSlider()
        Home.animatePins()
        Home.showLabelOnHover()
    },

    // Initiate the intro slider
    initSlider : () => {

        if (document.querySelector('.slider') != null) {

            Home.slider = tns({
                container            : '.slider',
                mode                 : 'gallery',
                items                : 1,
                slideBy              : 'page',
                autoplay             : true,
                autoplayButton       : false,
                autoplayButtonOutput : false,
                controls             : false,
                nav                  : false,
                animateDelay         : 5000,
                speed                : 1000
            });

        }
    },


    // Animate the map pins
    animatePins : () => {

        // If the site uses IE remove the class immediately
        if (!window.IntersectionObserver )
            Home.markers.forEach(function(image){Home.markers.classList.remove('hidden')})

        // If the site supports IO do the rest
        if (!!window.IntersectionObserver ){

            // Create the observer
            var observer = new IntersectionObserver((entries, observer) => { 
                
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.remove('hidden');
                        observer.unobserve(entry.target);
                    }
                });

                }, { threshold: [.1] });
            
            Home.markers.forEach(item => { observer.observe(item) });
        }

    },

    // Show and hide the label when we're hovering a pin
    showLabelOnHover : () => {
        Home.markers.forEach(marker => {

            marker.addEventListener('mouseenter' , () => {
                if (marker.dataset.location) {
                    Home.label.removeAttribute('hidden')
                    Home.label.innerHTML = marker.dataset.location;
                }
                
            })
            marker.addEventListener('mouseleave' , () => {Home.label.setAttribute('hidden' , null)})
            marker.addEventListener('mousemove'  , (event) => {
                Home.label.style.top  = `${event.clientY}px`
                Home.label.style.left = `${event.clientX}px`
            })


        })
    }




}

// Launch The Script
Home.liftoff()